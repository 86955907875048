import { LanguageCode, OtpType, languageCodeStringToEnum, getNrOfOtpTypes, FlowType, flowTypeEnumToString } from "@/enums";
import { loading, replaceState, getTranslations, translation } from "@/utils/common";
import { CheckoutInfo, OtpResponse, TextResourceList } from "@/entities";
import { defineCustomElements } from "@riverty/web-components/loader";
import { getTextResources } from "tests/entities";
import { get } from "@/utils/httpRequest";
import { useRouter } from "vue-router";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { otpKey } from "@/store";

export default defineComponent({
    name: "SelectOtpTypeView",
    props: {
        scaSessionId: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            data: new CheckoutInfo(),
            redirectUrl: <string | null>"",
            selectedOtpType: OtpType.Unset,
            textResourceList: new TextResourceList(),
            OtpType,
            requestCodeDisabled: true,
            emailDisabled: false,
            smsDisabled: false,
            store: useStore(otpKey),
        };
    },
    async created() {
        replaceState();
        defineCustomElements();
        this.data = this.store.state.otp;
        this.redirectUrl = useRouter().currentRoute.value.query.merchantUrl as string;
        if (!this.redirectUrl) {
            this.redirectUrl = this.data.redirectUrl;
        }

        loading();
        try {
            const response = <OtpResponse>await get(`api/verifyotp/init/${this.scaSessionId}?merchantUrl=${this.redirectUrl}`);

            //redirect to error (or restart) view
            if (!response.isSuccess) {
                this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${response.data?.redirectUrl ?? this.redirectUrl}`);
            }

            //redirect back to merchant
            if (response.data && response.data.redirectUrl && response.returnToMerchant) {
                window.location.replace(response.data.redirectUrl);
            }

            this.data = response.data ?? new CheckoutInfo();
            this.store.commit("setOtp", this.data);
        } catch (e) {
            console.log("Error while loading page data.", e);
        } finally {
            loading();
        }

        const distinctOtpTypes = this.data.otpInfo.map((item) => item.otpType).filter((value, index, self) => self.indexOf(value) === index);

        if (distinctOtpTypes.length > 0 && distinctOtpTypes.length < getNrOfOtpTypes()) {
            const latestType = this.data.otpInfo[this.data.otpInfo.length - 1].otpType;

            if (latestType == OtpType.Email || latestType == OtpType[OtpType.Email]) {
                this.emailDisabled = true;
            } else {
                this.smsDisabled = true;
            }
        } else if (distinctOtpTypes.length == getNrOfOtpTypes()) {
            this.emailDisabled = true;
            this.smsDisabled = true;
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null) {
            loading();

            try {
                this.textResourceList = (await this.getTextResources(this.data.countryCode, languageCodeStringToEnum(this.data.conversationLanguage), fetchFromTest, httpsAgent)) as TextResourceList;
            } catch {
                console.log("Error fetching translations.");
            } finally {
                loading();
            }
        },
        hasSteps() {
            return this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpEmailDob) || this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpSmsDob);
        },
        changeOtpType(newValue: OtpType) {
            if (this.emailDisabled && newValue == OtpType.Email) return;
            if (this.smsDisabled && newValue == OtpType.Sms) return;

            this.selectedOtpType = newValue;
            const btn = document.getElementById("redirectBtn");

            if (this.selectedOtpType != OtpType.Unset && btn) {
                btn.removeAttribute("disabled");
            }
        },
        async goBack() {
            //change status to "UserCancel" and redirect to merchant
            const response = await get(`api/verifyotp/cancel/${this.scaSessionId}`);
            if (!response.isSuccess) {
                //redirect to error view
                this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${response.data.redirectUrl}`);
            }

            const redirectUrl = response.data.redirectUrl ?? "https://www.riverty.com";

            window.location.replace(redirectUrl);
        },
        async redirect() {
            if (this.selectedOtpType == OtpType.Unset) return;

            const otpType = this.selectedOtpType == OtpType.Email ? "Email" : "Sms";
            const response = await get(`api/verifyotp/requestcode/${otpType}/${this.scaSessionId}`);
            this.data = response.data;
            this.store.commit("setOtp", response.data);

            //redirect to error view
            if (!response.isSuccess) {
                this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${this.redirectUrl}`);
            }

            //redirect back to merchant
            if (response.data && response.data.redirectUrl && response.returnToMerchant) {
                window.location.replace(response.data.redirectUrl);
            }

            //redirect to next step
            if (response.view) {
                this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${this.redirectUrl}`);
            }
        },
        async getTextResources(countryCode: string | null | undefined, languageCode: LanguageCode, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = ["OtpClose", "OtpIntroTitle", "OtpIntroDescription", "OtpIntroSmsAndEmailDescription", "OtpSelectMethodViaEmail", "OtpSelectMethodViaSms", "OtpRequestCode"];

            return await getTranslations(sysnameList, countryCode, languageCode, fetchFromTest, httpsAgent);
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            return translation(this.textResourceList?.textResourceItems, sysName, replace);
        },
    },
});
