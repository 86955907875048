import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/riverty-logo-green.svg'
import _imports_1 from '@/assets/icon-success.svg'


const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container margin-top-56" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md-8 offset-md-2" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  key: 1,
  src: _imports_0,
  alt: "Riverty logo",
  height: "32"
}
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.data.clientLogoUrl)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.data.clientLogoUrl,
                      alt: `${_ctx.data.clientDisplayName} logo`,
                      height: "32"
                    }, null, 8, _hoisted_7))
                  : (_openBlock(), _createElementBlock("img", _hoisted_8))
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[0] || (_cache[0] = _createElementVNode("img", {
                  class: "align-items-start margin-bottom-28",
                  src: _imports_1,
                  alt: "Success",
                  width: "88"
                }, null, -1)),
                _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.getTranslation(_ctx.hasDoBStep() ? "OtpThankYou" : "OtpSuccessTitle")), 1),
                (_ctx.hasDoBStep())
                  ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.getTranslation("OtpWeAreReviewingInformation")), 1))
                  : _createCommentVNode("", true),
                _cache[1] || (_cache[1] = _createElementVNode("p", {
                  id: "description",
                  class: "mb-3"
                }, null, -1)),
                _createElementVNode("p", {
                  innerHTML: _ctx.getTranslation('OtpSuccessDescription', [_ctx.data?.redirectUrl ?? 'https://riverty.com'])
                }, null, 8, _hoisted_12)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}