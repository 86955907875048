import { ScaType, OtpType, LanguageCode, languageCodeEnumToString, SignicatStatusType, FlowType } from "@/enums";

class CheckoutInfo {
    scaSessionId = "";
    scaType = ScaType.None;
    signicatStatus: SignicatStatusType | null = null;
    conversationLanguage: string | null = languageCodeEnumToString(LanguageCode.EN);
    countryCode: string | null = "en";
    redirectUrl: string | null = null;
    email: string | null = null;
    phoneNumber: string | null = null;
    isResentCode: boolean | null = null;
    flowType: FlowType | string = FlowType.VerifyOtpSmsEmail;
    otpInfo = new Array<OtpCode>();
    clientLogoUrl: "" | null = null;
    clientDisplayName: "" | null = null;
}

class OtpCode {
    expireAt = new Date();
    otpType: OtpType | string = OtpType.Email;
    email: string | null = null;
    phoneNumber: string | null = null;
    failedAttempts = 0;
}

export { CheckoutInfo, OtpCode };
