import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/riverty-logo-green.svg'


const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md-8 offset-md-2" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  key: 1,
  src: _imports_0,
  alt: "Riverty logo",
  height: "32"
}
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "title mb-3" }
const _hoisted_11 = { class: "subtitle mb-3" }
const _hoisted_12 = { class: "r-font-size-200 w-100" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "card-footer" }
const _hoisted_15 = { class: "footer" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-sm-auto mb-3" }
const _hoisted_18 = { class: "col-sm-auto order-sm-first" }
const _hoisted_19 = { class: "title mb-3" }
const _hoisted_20 = { class: "subtitle mb-3" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col-sm-auto mb-3" }
const _hoisted_23 = { class: "col-sm-auto order-sm-first" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_progress_steps = _resolveComponent("r-progress-steps")!
  const _component_r_button = _resolveComponent("r-button")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.data.clientLogoUrl)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.data.clientLogoUrl,
                      alt: `${_ctx.data.clientDisplayName} logo`,
                      height: "32"
                    }, null, 8, _hoisted_7))
                  : (_openBlock(), _createElementBlock("img", _hoisted_8))
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.hasSteps())
                  ? (_openBlock(), _createBlock(_component_r_progress_steps, {
                      key: 0,
                      class: "margin-bottom-28",
                      "active-step": "3",
                      steps: "3"
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.getTranslation(_ctx.hasSteps() ? "OtpEnterYourDob" : "OtpConfirmDobOnly")), 1),
                _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.getTranslation(_ctx.hasSteps() ? "OtpConfirmDob" : "OtpConfirmDobOnlyDescription")), 1),
                _createElementVNode("label", _hoisted_12, [
                  _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpDobLabel")) + " ", 1),
                  _withDirectives(_createElementVNode("input", {
                    id: "birthDateInput",
                    class: _normalizeClass(["r-input", {
                                            check: _ctx.birthDateIsValid,
                                            'r-input-error': _ctx.birthDateInputError,
                                        }]),
                    "data-maska": "##/##/####",
                    placeholder: _ctx.getBirthDatePlaceholder(),
                    inputmode: "numeric"
                  }, null, 10, _hoisted_13), [
                    [_directive_maska, void 0, _ctx.birthDateMaskaOptions]
                  ]),
                  _createElementVNode("span", {
                    class: _normalizeClass(["hint", { 'hint-error': _ctx.birthDateInputError }])
                  }, _toDisplayString(_ctx.birthDateHintMessage), 3)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_r_button, {
                        id: "continueBtn",
                        class: "footer-button",
                        expanded: "",
                        disabled: !_ctx.birthDateIsValid,
                        onClick: _ctx.validateDOB,
                        onKeyup: _withKeys(_ctx.validateDOB, ["enter"])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpContinueButton")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick", "onKeyup"])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      (_ctx.hasSteps())
                        ? (_openBlock(), _createBlock(_component_r_button, {
                            key: 0,
                            href: "#modal-content",
                            class: "footer-button",
                            variant: "secondary",
                            expanded: "",
                            onClick: _ctx.confirmExit
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpClose")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (!_ctx.hasSteps())
                        ? (_openBlock(), _createBlock(_component_r_button, {
                            key: 1,
                            class: "footer-button",
                            variant: "secondary",
                            expanded: "",
                            onClick: _ctx.goBack
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpBackButton")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ]),
            (_ctx.confirmExitOpen)
              ? (_openBlock(), _createBlock(_component_ModalComponent, { key: 0 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.getTranslation("OtpExitVerificationPrompt")), 1),
                      _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.getTranslation("OtpExitVerificationDescription")), 1),
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _createVNode(_component_r_button, {
                            id: "exit",
                            class: "footer-button",
                            expanded: "",
                            onClick: _ctx.goBack
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpExitVerification")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _createElementVNode("div", _hoisted_23, [
                          _createVNode(_component_r_button, {
                            class: "footer-button",
                            variant: "secondary",
                            expanded: "",
                            onClick: _ctx.closeModal
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpContinueVerification")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}