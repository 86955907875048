import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/riverty-logo-green.svg'


const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md-8 offset-md-2" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  key: 1,
  src: _imports_0,
  alt: "Riverty logo",
  height: "32"
}
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = {
  for: "code",
  class: "r-label"
}
const _hoisted_12 = ["onkeyup", "onpaste"]
const _hoisted_13 = {
  id: "codeError",
  class: "error hidden"
}
const _hoisted_14 = {
  id: "codeErrorLabel",
  class: "margin-left-10"
}
const _hoisted_15 = { class: "card-footer" }
const _hoisted_16 = { class: "footer" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "col-sm-auto mb-3" }
const _hoisted_19 = { class: "col-sm-auto mb-3 order-sm-first" }
const _hoisted_20 = { class: "col mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_progress_steps = _resolveComponent("r-progress-steps")!
  const _component_r_icon = _resolveComponent("r-icon")!
  const _component_r_button = _resolveComponent("r-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.data.clientLogoUrl)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.data.clientLogoUrl,
                      alt: `${_ctx.data.clientDisplayName} logo`,
                      height: "32"
                    }, null, 8, _hoisted_7))
                  : (_openBlock(), _createElementBlock("img", _hoisted_8))
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.hasSteps())
                  ? (_openBlock(), _createBlock(_component_r_progress_steps, {
                      key: 0,
                      class: "margin-bottom-28",
                      "active-step": "2",
                      steps: "3"
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.getTranslation("OtpEnterYourCode")), 1),
                _withDirectives(_createElementVNode("p", { class: "subtitle margin-bottom-40" }, _toDisplayString(_ctx.getTranslation("OtpWeSentCodeTo", [(_ctx.selectedOtpType == _ctx.OtpType.Sms ? _ctx.data?.phoneNumber : _ctx.data?.email) ?? ""])), 513), [
                  [_vShow, _ctx.selectedOtpType == _ctx.OtpType.Email]
                ]),
                _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.getTranslation("OtpCodeLabel")), 1),
                _createVNode(_component_r_icon, {
                  name: "speech-bubble",
                  class: "inline-icon"
                }),
                _createElementVNode("input", {
                  id: "code",
                  class: "r-input input-with-icon",
                  type: "text",
                  autocomplete: "one-time-code",
                  name: "inputCode",
                  onkeyup: _ctx.validateNextBtn,
                  onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.validateCode && _ctx.validateCode(...args)), ["enter"])),
                  onpaste: _ctx.pastedCode,
                  required: ""
                }, null, 40, _hoisted_12),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_r_icon, {
                    name: "circled-exclamation-mark",
                    class: "error-icon"
                  }),
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.getTranslation("OtpCodeSmsInfo")), 1)
                ]),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                _withDirectives(_createElementVNode("p", { class: "text-grey" }, _toDisplayString(_ctx.getTranslation(_ctx.selectedOtpType == _ctx.OtpType.Email ? "OtpCodeEmailInfo" : "OtpCodeSmsInfo")), 513), [
                  [_vShow, _ctx.selectedOtpType != _ctx.OtpType.Unset && !_ctx.isCodeEntered]
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_r_button, {
                        id: "nextBtn",
                        class: "footer-button",
                        expanded: "",
                        type: "submit",
                        disabled: "",
                        "on:click": _ctx.validateCode
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpContinueButton")), 1)
                        ]),
                        _: 1
                      }, 8, ["on:click"])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_r_button, {
                        class: "footer-button",
                        variant: "secondary",
                        expanded: "",
                        onClick: _ctx.goBack
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpBackButton")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createVNode(_component_r_button, {
                        id: "requestNewCodeBtn",
                        variant: "text",
                        class: "footer-button float-end",
                        expanded: "",
                        disabled: "",
                        onClick: _ctx.reSend
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_r_icon, {
                            name: "arrows-circled",
                            size: "s"
                          }),
                          _createTextVNode(" " + _toDisplayString(_ctx.getTranslation("OtpReSendCodeButton")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}