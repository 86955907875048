<style lang="scss" scoped src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="page">
        <main>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-8 offset-md-2">
                        <div class="card">
                            <div class="card-header">
                                <img v-if="data.clientLogoUrl" v-bind:src="data.clientLogoUrl" v-bind:alt="`${data.clientDisplayName} logo`" height="32" />
                                <img v-else src="@/assets/riverty-logo-green.svg" alt="Riverty logo" height="32" />
                            </div>
                            <div class="card-body">
                                <r-progress-steps v-if="hasSteps()" class="margin-bottom-28" active-step="2" steps="3"></r-progress-steps>
                                <h5 class="title">{{ getTranslation("OtpEnterYourCode") }}</h5>
                                <p class="subtitle margin-bottom-40" v-show="selectedOtpType == OtpType.Email">
                                    {{ getTranslation("OtpWeSentCodeTo", [(selectedOtpType == OtpType.Sms ? data?.phoneNumber : data?.email) ?? ""]) }}
                                </p>

                                <label for="code" class="r-label">{{ getTranslation("OtpCodeLabel") }}</label>
                                <r-icon name="speech-bubble" class="inline-icon"></r-icon>
                                <input id="code" class="r-input input-with-icon" type="text" autocomplete="one-time-code" name="inputCode" :onkeyup="validateNextBtn" v-on:keyup.enter="validateCode" :onpaste="pastedCode" required />
                                <div id="codeError" class="error hidden">
                                    <r-icon name="circled-exclamation-mark" class="error-icon"></r-icon>
                                    <span id="codeErrorLabel" class="margin-left-10">{{ getTranslation("OtpCodeSmsInfo") }}</span>
                                </div>

                                <br />

                                <p class="text-grey" v-show="selectedOtpType != OtpType.Unset && !isCodeEntered">{{ getTranslation(selectedOtpType == OtpType.Email ? "OtpCodeEmailInfo" : "OtpCodeSmsInfo") }}</p>
                            </div>
                            <div class="card-footer">
                                <div class="footer">
                                    <div class="row">
                                        <div class="col-sm-auto mb-3">
                                            <r-button id="nextBtn" class="footer-button" expanded="" type="submit" disabled @:click="validateCode">{{ getTranslation("OtpContinueButton") }}</r-button>
                                        </div>
                                        <div class="col-sm-auto mb-3 order-sm-first">
                                            <r-button class="footer-button" variant="secondary" expanded="" @click="goBack">{{ getTranslation("OtpBackButton") }}</r-button>
                                        </div>
                                        <div class="col mb-3">
                                            <r-button id="requestNewCodeBtn" variant="text" class="footer-button float-end" expanded="" disabled v-on:click="reSend">
                                                <r-icon name="arrows-circled" size="s"></r-icon>
                                                {{ getTranslation("OtpReSendCodeButton") }}
                                            </r-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
