/* eslint @typescript-eslint/no-duplicate-enum-values: 0 */
enum OtpType {
    Unset = -1,
    Email,
    Sms,
}

function getNrOfOtpTypes() {
    return Object.keys(OtpType).filter((item) => {
        return isNaN(Number(item)) && item != "Unset";
    }).length;
}

function strToEnum(value: string) {
    const strEnum = value as unknown as OtpType;
    return OtpType[strEnum];
}

function otpTypeEnumToString(otpType: OtpType | string) {
    switch (otpType) {
        case OtpType.Email:
            return "Email";
        case OtpType.Sms:
            return "Sms";
        default:
            return "Unset";
    }
}

export { OtpType, getNrOfOtpTypes, strToEnum, otpTypeEnumToString };
