import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { CompletedView, ConfirmView, DateOfBirthView, ErrorView, ExpiredView, InitView, IntroView, SelectOtpView, SuccessView } from "@/views";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/verifyotpauthentication/init/:scaSessionId",
        name: "One Time Passcode - Init",
        props: true,
        component: InitView,
    },
    {
        path: "/verifyDoB/:scaSessionId",
        name: "One Time Passcode - Confirm date of birth",
        props: true,
        component: DateOfBirthView,
    },
    {
        path: "/selectOtpType/:scaSessionId",
        name: "One Time Passcode - Select OTP Type",
        props: true,
        component: SelectOtpView,
    },
    {
        path: "/confirm/:scaSessionId",
        name: "One Time Passcode - Confirm",
        props: true,
        component: ConfirmView,
    },
    {
        path: "/success/:scaSessionId",
        name: "One Time Passcode - Success",
        props: true,
        component: SuccessView,
    },
    {
        path: "/completed",
        name: "One Time Passcode - Completed",
        component: CompletedView,
    },
    {
        path: "/error/:scaSessionId",
        name: "One Time Passcode - Error",
        props: true,
        component: ErrorView,
    },
    {
        path: "/intro/:scaSessionId",
        props: true,
        name: "One Time Passcode - Intro",
        component: IntroView,
    },
    {
        path: "/expired/:scaSessionId",
        props: true,
        name: "One Time Passcode - Expired",
        component: ExpiredView,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "Page Not Found",
        component: ErrorView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.name) {
        document.title = to.name.toString();
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = to.name.toString();
        document.head.appendChild(meta);
    }
    next();
});

export default router;
