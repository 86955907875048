<style lang="scss" scoped src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="page">
        <main>
            <div class="container margin-top-56">
                <div class="row">
                    <div class="col-12 col-md-8 offset-md-2">
                        <div class="card">
                            <div class="card-header">
                                <img v-if="data?.clientLogoUrl" v-bind:src="data.clientLogoUrl" v-bind:alt="`${data.clientDisplayName} logo`" height="32" />
                                <img v-else src="@/assets/riverty-logo-green.svg" alt="Riverty logo" height="32" />
                            </div>
                            <div class="card-body">
                                <img class="align-items-start margin-bottom-28" src="@/assets/icon-error.svg" alt="Error" width="88" />

                                <div class="title">{{ getTranslation(isDeadEnd ? "OtpDeadEndErrorTitle" : "OtpErrorTitle") }}</div>
                                <p class="subtitle" v-if="scaSessionId">{{ getTranslation(isDeadEnd ? "OtpDeadEndErrorSubTitle" : "OtpErrorSubTitle") }}</p>
                            </div>
                            <div class="card-footer">
                                <div class="footer">
                                    <div class="row">
                                        <div class="col-sm-auto mb-3">
                                            <r-button class="footer-button" :disabled="!scaSessionId" expanded="" v-show="!isDeadEnd && !isSmsDisabled" v-on:click="reStartVerification()">{{ getTranslation("OtpRestartButton") }}</r-button>
                                        </div>
                                        <div class="col-sm-auto order-sm-first">
                                            <r-button class="footer-button" :disabled="!data?.redirectUrl" :variant="isDeadEnd ? 'primary' : 'secondary'" expanded="" v-on:click="redirectToMerchant()">{{
                                                getTranslation("OtpReturnToShopButton")
                                            }}</r-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
