async function base(path: string, requestOptions: RequestInit, fetchFromTest: CallableFunction | null = null) {
    const provider = fetchFromTest ?? fetch;
    const response: Response = await provider(`${process.env.VUE_APP_API_URL}/${path}`, requestOptions);
    try {
        const data = await response?.json();

        if (response.status == 401) {
            window.location.href = data.urlRedirect;
        }

        return data;
    } catch {
        return;
    }
}

async function get(path: string, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        agent: httpsAgent,
        credentials: "include",
    } as RequestInit;

    return base(path, requestOptions, fetchFromTest);
}

async function post(path: string, body: unknown, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
        },
        agent: httpsAgent,
        credentials: "include",
    } as RequestInit;

    return base(path, requestOptions, fetchFromTest);
}

export { get, post };
