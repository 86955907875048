import { TextResourceItem, TextResourceList } from "@/entities";
import { post } from "./httpRequest";
import { LanguageCode } from "@/enums";
import { getTextResources } from "tests/entities";

interface ImportObj {
    url: string;
    isModule: boolean;
}

function dynamicImport(item: ImportObj) {
    const rivertyOnboardingModule = document.createElement("script");
    rivertyOnboardingModule.setAttribute("src", item.url);

    if (item.isModule) rivertyOnboardingModule.setAttribute("type", "module");
    else rivertyOnboardingModule.setAttribute("nomodule", "");

    document.head.appendChild(rivertyOnboardingModule);

    return rivertyOnboardingModule;
}

async function getTranslations(sysNameList: string[], countryCode: string | null | undefined, languageCode: LanguageCode, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null): Promise<TextResourceList | getTextResources> {
    if (!countryCode) countryCode = "en";

    const response = await post(
        "api/text-resources/list",
        {
            countryCode,
            languageCode,
            sysNameList,
        },
        fetchFromTest,
        httpsAgent,
    );

    if (!fetchFromTest) return response;

    return { obj: response, list: sysNameList };
}

function translation(list: Array<TextResourceItem>, sysName: string, replace: string[] = []): string {
    let value = list.find((x) => x.sysName == sysName)?.value;

    replace.forEach((item: string, index: number) => {
        value = value?.replace(`{${index}}`, item);
    });

    return value ?? "";
}

function currency(value: number, currency = "EUR", location = "en-US") {
    if (typeof value !== "number") {
        return value;
    }
    const formatter = new Intl.NumberFormat(location, {
        style: "currency",
        currency: currency,
    });
    return formatter.format(value);
}

function loading() {
    const progressBar = document.querySelector("#progress-bar") as HTMLDivElement;
    progressBar.style.display = progressBar.style.display == "flex" ? "none" : "flex";
}

function replaceState() {
    window.history.replaceState(null, document.title, location.href);
}

export { ImportObj, dynamicImport, getTranslations, translation, currency, loading, replaceState };
