import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/riverty-logo-green.svg'


const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-md-8 offset-md-2" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  key: 1,
  src: _imports_0,
  alt: "Riverty logo",
  height: "32"
}
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "subtitle margin-bottom-40" }
const _hoisted_12 = { class: "subtitle margin-bottom-40" }
const _hoisted_13 = { class: "mb-4" }
const _hoisted_14 = { class: "card-footer" }
const _hoisted_15 = { class: "footer" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-sm-auto mb-3" }
const _hoisted_18 = { class: "col-sm-auto order-sm-first" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_progress_steps = _resolveComponent("r-progress-steps")!
  const _component_r_radio_button = _resolveComponent("r-radio-button")!
  const _component_r_button = _resolveComponent("r-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.data.clientLogoUrl)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.data.clientLogoUrl,
                      alt: `${_ctx.data.clientDisplayName} logo`,
                      height: "32"
                    }, null, 8, _hoisted_7))
                  : (_openBlock(), _createElementBlock("img", _hoisted_8))
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.hasSteps())
                  ? (_openBlock(), _createBlock(_component_r_progress_steps, {
                      key: 0,
                      class: "margin-bottom-28",
                      "active-step": "1",
                      steps: "3"
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.getTranslation("OtpIntroTitle")), 1),
                _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.getTranslation("OtpIntroDescription")), 1),
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.getTranslation("OtpIntroSmsAndEmailDescription")), 1),
                _createElementVNode("form", null, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_r_radio_button, {
                      id: "emailRadio",
                      name: "otpTypeRadio",
                      value: _ctx.OtpType.Email,
                      disabled: _ctx.emailDisabled,
                      checked: _ctx.selectedOtpType == _ctx.OtpType.Email,
                      onKeyup: [
                        _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.changeOtpType(_ctx.OtpType.Email)), ["space"])),
                        _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.changeOtpType(_ctx.OtpType.Email)), ["enter"]))
                      ],
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeOtpType(_ctx.OtpType.Email)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpSelectMethodViaEmail")) + " " + _toDisplayString(_ctx.data.email), 1)
                      ]),
                      _: 1
                    }, 8, ["value", "disabled", "checked"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_r_radio_button, {
                      id: "smsRadio",
                      name: "otpTypeRadio",
                      value: _ctx.OtpType.Sms,
                      disabled: _ctx.smsDisabled,
                      checked: _ctx.selectedOtpType == _ctx.OtpType.Sms,
                      onKeyup: [
                        _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.changeOtpType(_ctx.OtpType.Sms)), ["space"])),
                        _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.changeOtpType(_ctx.OtpType.Sms)), ["enter"]))
                      ],
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeOtpType(_ctx.OtpType.Sms)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpSelectMethodViaSms")) + " " + _toDisplayString(_ctx.data.phoneNumber), 1)
                      ]),
                      _: 1
                    }, 8, ["value", "disabled", "checked"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_r_button, {
                        id: "redirectBtn",
                        class: "footer-button",
                        expanded: "",
                        disabled: "",
                        onClick: _ctx.redirect,
                        onKeyup: _withKeys(_ctx.redirect, ["enter"])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpRequestCode")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick", "onKeyup"])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_r_button, {
                        class: "footer-button order-md-1",
                        variant: "secondary",
                        expanded: "",
                        onClick: _ctx.goBack
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTranslation("OtpClose")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}